<footer class="footer-section">
    <div class="footer-top">
        <div class="px-3">
            <div class="row">
                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="widget company-intro-widget">
                        <a class="site-logo"><img src="../../../assets/images/logo-light.svg" alt="logo"></a>
                    </div>
                </div>
                <div class="col-lg-20 col-md-6 col-sm-6">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">About</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://beingatfullpotential.com/about/">Team</a></li>
                            <li><a target="_blank"
                                    href=" https://beingatfullpotential.com/human-potential-model-validation/">Human Potential Assessment</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com/contact/">Contact us</a></li>
                        </ul>
                    </div>
                </div>
                <!-- widget end -->
                <div class="col-lg-20 col-md-6 col-sm-6">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">Academy</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank"
                                    href="https://academy.beingatfullpotential.com/courses/individual-certification-training/">Human Potential Coach Certification</a>
                            </li>
                            <li><a target="_blank"
                                    href="https://www.youtube.com/playlist?list=PLfhHxjJm8_O81FGBYCU-Fsfj7Jl6neb8l">Employee Experience Training</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com/impact/">Podcasts & Case Studies</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-20 col-md-6 col-sm-6">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">Services</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://beingatfullpotential.com/individuals/">Visionaries &
                                    change Agents</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com/teams/">Teams
                                </a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.com/organizations/">Organizations
                                </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-20 col-md-6 col-sm-6">
                    <div class="widget course-links-widget">
                        <h5 class="widget-title">Quick Links</h5>
                        <ul class="courses-link-list">
                            <li><a target="_blank" href="https://beingatfullpotential.com/our-team/">Find a Coach</a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.io/#/faqs">FAQs
                                </a></li>
                            <li><a target="_blank" href="https://beingatfullpotential.io/#/privacy">Privacy Policy
                                </a></li>
                        </ul>
                    </div>
                </div>
                <!-- widget end -->

                <!-- widget end -->
                <!-- <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="widget newsletter-widget">
              <h5 class="widget-title">Newsletter</h5>
              <div class="footer-newsletter">
                <p>Sign Up to Our Newsletter to Get Latest Updates & Services</p>
                <form class="news-letter-form">
                  <input type="email" name="news-email" id="news-email" placeholder="Your email address">
                  <input type="submit" value="Send">
                </form>
              </div>
            </div>
                  </div> -->
                <!-- widget end -->

            </div>
        </div>
    </div>
    <!-- <div class="footer-bottom">
            <div class="px-3">
                <div class="row">
                    <div class="col-md-9 col-sm-6 text-sm-left text-center">
                        <span class="copy-right-text">© 2023 All Rights Reserved.</span>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- footer-bottom end -->
</footer>